import "react-app-polyfill/ie11";
import React, { useState, useCallback } from "react";
import ReactDOM from "react-dom";
import Root from "./client_customizations/containers/Root/Root";
import { BrowserRouter as Router } from "react-router-dom";
import {IntlProvider} from "react-intl";
import * as serviceWorker from "./serviceWorker";
import "./utils/polyfills";
import MESSAGES from "./context/translations/messages";

const App = () => {
  const [language, setLanguage] = useState('EN')

  const changeLanguage = useCallback(lang => {
    setLanguage(lang)
  }, [])

  return (
    <IntlProvider locale={language} messages={MESSAGES[language]}>
      <Router>
          <Root language={language} changeLanguage={changeLanguage}/>
      </Router>
    </IntlProvider>
  )
}

const rootElement = document.getElementById("root") 
ReactDOM.render(<App />, rootElement)

// ReactDOM.render(
//   <IntlProvider locale={language} messages={messages[language]}>
//     <Router>
//         <Root changeLanguage={changeLanguage}/>
//     </Router>
//   </IntlProvider>,
//   document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
