import USER_PREF_PRESETS_CLIENT_CUSTOM from "../../client_customizations/context/UserPrefs/USER_PREF_PRESETS_CLIENT_CUSTOM";

const USER_PREF_PRESETS = {
  canTurnInClunker: false,
  chargerInstallAllowed: null,
  chargerLevel: "level_2",
  electricMilesPortionForPhev: 60,
  hasOvernightCharger: null,
  gasolinePriceInCentsPerGal: 295,
  hasRegularPowerOutlet: null,
  householdIncome: 70000,
  householdSize: 3,
  electricityProvider: "",
  includeResaleValue: true,
  interestRateAsBasisPoints: 350,
  locallyAvailableVehicleFilter: false,
  maxBudget: 70000,
  milesDrivenAnnually: 14400,
  milesDrivenDaily: 300,
  minSeats: 2,
  monthsOfOwnership: 60,
  parkingLocation: "",
  purchaseMethod: "cash",
  showAllRates: false,
  showGasolineCost: true,
  showHomeElectricityCost: true,
  showRateDetails: true,
  showDetailsForRate: "EV2-A",
  taxFilingStatus: "joint",
  vehicleChargingPattern: "After midnight; Before 3pm",
  vehicleIdForIncentives: "",
  vehicleHandleForIncentives: "Acura_ZDX_A-Spec_AWD_BEV_2024",
  vehicleInsuranceYearly: null,
  vehicleRange: 300,
  vehicleSortDirection: "asc",
  vehicleSortType: "price",
  workingZipcode: ``,
  zipcode: 90504,
  chargerBudget: 2000,
  chargerCordLength: 25,
  chargerSortType: "price",
  chargerSortDirection: "asc",
  vehicleMakeFilter: "Acura",
  vehicleModelFilter: "2024 Acura ZDX",
  vehicleTrimFilter: "A-Spec AWD",
  vehicleAgeFilter: "",
  incentiveSortType: "",
  incentiveSortDirection: "",
  eventDistance: "25",
  eventLocation: "In Person",
  eventsSortType: "date-asc",
  salesTax: ".0725",
  costPublicCharging: "0.2",
  publicChargingPercentage: "20",
  electricityRate: ".16611",
  municipality: "",
  country: "United States" || null,
  userRegion: "",

  vehicleFuelTypeFilters: {
    bev: false,
    phev: false,
  },

  vehicleFormFactorFilters: {
    sedan: false,
    hatchback: false,
    coupe: false,
    crossover: false,
    minivan: false,
    suv: false,
    wagon: false,
    truck: false,
  },

  chargerWifiFilter: {
    yes: false,
    no: false,
  },

  chargerTypeFilters: {
    mounted: false,
    portable: false,
  },

  chargerFormFactorFilters: {
    "6-20": false,
    "6-30": false,
    "10-30": false,
    "14-30": false,
    "6-50": false,
    "14-50": false,
    "14-60": false,
    Hardwired: false,
  },
};

export default { ...USER_PREF_PRESETS, ...USER_PREF_PRESETS_CLIENT_CUSTOM };
