import VI_CUSTOM from "../../client_customizations/context/translations/vi_client_custom";

const VI = {
    "electricVehicles": "Xe Điện",
    "usedElectricVehicles": "Xe Điện Cũ",
    "compareVehicles": "So sánh các xe",
    "incentives": "Khích lệ",
    "": "",
    "chargingStations": "Trạm sạc",
    "homeChargers": "Bộ sạc tại nhà",
    "dealers": "Đại lý",
    "newVehicles": "Xe mới",
    "electricians": "Thợ điện",
    "electricity": "Điện",
    "gasoline": "Xăng",
    "total": "Tổng cộng",
    "description": "Mô tả",
    "detailedCalculations": "TÍNH TOÁN CHI TIẾT",
    "charging": "Sạc",
    "emissions": "Khí thải",
    "source": "Nguồn",
    "vehicles": "Xe",
    "make": "Hiệu",
    "model": "Kiểu xe",
    "all": "Tất cả",
    "age": "Tuổi tác",
    "zipcode": "Bưu chánh",
    "personalizeIncentives": "KHÍCH LỆ CÁ NHÂN",
    "vehicle.miles": "dặm",
    "vehicle.trees": "cây",
    "vehicle.view": "Xem",
    "vehicle.more": "nhiều thêm",
    "vehicle.fewer": "ít hơn",
    "vehicle.greater": "nhiều hơn",
    "vehicle.plugInHybrid": "Xe Xăng Chạy Điện Cắm Sạc",
    "vehicle.allElectric": "Tất Cả Xe Điện",
    "vehicle.gas": "Xăng",
    "vehicle.afterIncentives": "SAU KHÍCH LỆ",
    "vehicle.msrp": "GIÁ BÁN LẺ NHÀ SẢN XUẤT KHUYẾN CÁO",
    "vehicle.estimatedIncentives": "KHÍCH LỆ ƯỚC TÍNH",
    "vehicle.type": "KIỂU DẠNG",
    "vehicle.fuelType": "LOẠI NHIÊN LIỆU",
    "vehicle.batterySize": "KÍCH CỠ PIN",
    "vehicle.electricRange": "QUÃNG ĐƯỜNG ĐI ĐƯỢC CHO MỘT LẦN SẠC",
    "vehicle.timeToChargeLvlTwo": "THỜI GIAN SẠC - MỨC 2",
    "vehicle.milesPerThirtyFastCharge": "SỐ DẶM ĐI VỚI MỖI 30 PHÚT SẠC NHANH",
    "vehicle.coTwoEmissions": "GIẢM KHÍ THẢI CO2",
    "vehicle.phev": "Điện và Xăng",
    "vehicle.bev": "Điện",
    "vehicle.age.one": "",
    "vehicle.age.two": "",
    "vehicle.age.four": "",
    "vehicle.type.sedan": "Xe nhà (Sedan)",
    "vehicle.type.hatchback": "Xe gia đình (Hatchback)",
    "vehicle.type.coupe": "Xe 2 chỗ (Coupe)",
    "vehicle.type.crossover": "Xe Crossover",
    "vehicle.type.minivan": "Xe van nhỏ",
    "vehicle.type.suv": "Xe SUV",
    "vehicle.type.wagon": "Xe thùng (Wagon)",
    "vehicle.type.truck": "Xe tải",
    "header.electricVehicles": "Xe điện",
    "header.incentives": "Khích lệ",
    "header.chargingStations": "Trạm sạc",
    "header.homeChargers": "Bộ sạc tại nhà",
    "footer.electricVehicles": "",
    "footer.compareVehicles": "",
    "footer.incentives": "",
    "footer.chargingStations": "",
    "footer.homeChargers": "",
    "footer.allRights": "Giữ tất cả bản quyền.",
    "footer.poweredBy": "Do Zappyride cung cấp:",
    "homepage.welcome": "Chào mừng quý vị đến EV Assistant",
    "homepage.welcomeSub": "Tìm rồi so sánh xe. {lineBreak} Xem các khích lệ và tiết kiệm cho xe điện. {lineBreak} Xem các lựa chọn sạc cho nhà và trên đường.",
    "homepage.linkCard.browseElectricVehicles": "TÌM XE ĐIỆN",
    "homepage.linkCard.discoverIncentives": "XEM CÁC KHÍCH LỆ",
    "homepage.linkCard.homeChargers": "",
    "homepage.vehicleCarousel.title": "Có sẵn {vehicleCount} xe điện. Hãy khám phá loại xe của quý vị.",
    "homepage.vehicleCarousel.subTitle": "",
    "homepage.vehicleCarousel.findEVButton": "Xem Tất Cả Xe",
    "graph.assumptions": "Giả thiết",
    "graph.yourEV": "Lựa Chọn Xe Điện của Quý Vị",
    "graph.similarGasVehicle": "Xe Xăng Tương Tự",
    "graph.graphValues": "SƠ ĐỒ ĐỊNH GIÁ",
    "graph.show": "Hiển thị ",
    "graph.hide": "Giấu ",
    "graph.moreExpensive": "mắc tiền hơn",
    "graph.cheaper": "rẻ hơn",
    "graph.toOwnOver": "sở hữu trong",
    "graph.years": "Năm",
    "graph.year": "Năm",
    "graph.costOfOwnership.title": "Chi Phí Sở Hữu",
    "graph.costOfOwnership.subTitle": "BMW mắc tiền hơn $1000 khi sở hữu trên 5 năm",
    "": "BMW rẻ hơn $1000 khi sở hữu trên 5 năm",
    "graph.costOfOwnership.subTitleThreeCars": "BMW rẻ nhất khi sở hữu trong vòng 5 năm",
    "graph.costOfOwnership.descriptionRow.vehicle": "Xe",
    "graph.costOfOwnership.descriptionRow.maintenance": "Bảo trì",
    "graph.costOfOwnership.descriptionRow.insurance": "Bảo hiểm",
    "graph.costOfOwnership.descriptionRow.electricity": "Điện",
    "graph.costOfOwnership.descriptionRow.gasoline": "Xăng",
    "graph.costOfOwnership.totalRow": "Tổng cộng",
    "graph.costOfOwnership.description": "Mô tả",
    "graph.costOfOwnership.chart.vehicle": "Khích Lệ thực sự cho xe, Giá bán lại",
    "graph.costOfOwnership.chart.electricity": "Điện",
    "graph.costOfOwnership.chart.gasoline": "Xăng",
    "graph.costOfOwnership.chart.maintenance": "Bảo trì",
    "graph.costOfOwnership.chart.insurance": "Bảo hiểm",
    "graph.title.costAnalysis": "Phân tích chi phí",
    "graph.title.speedOfCharge": "Tốc độ sạc",
    "graph.title.electricRange": "Quãng đường đi cho một lần sạc",
    "graph.monthlyCostToFill.title": "Chi phí đổ xăng hàng tháng",
    "graph.speedOfCharge.levelTwoChargingSpeed": "Tốc độ sạc mức 2",
    "graph.speedOfCharge.fastChargingSpeed": "Tốc độ sạc nhanh",
    "graph.speedOfCharge.mphLevelTwoCharger": "số dặm đi được với mỗi giờ sạc mức 2",
    "graph.speedOfCharge.thirtyFastCharge": "số dặm đi được với mỗi 30 phút sạc nhanh",
    "graph.speedOfCharge.subTitle": "BMW đi được ít hơn 61 dặm với mỗi 30 phút sạc nhanh",
    "": "BMW đi được nhiều hơn 61 dặm với mỗi 30 phút sạc nhanh",
    "": "BMW đi được ít hơn 8 dặm với mỗi giờ sạc mức 2",
    "": "BMW đi được nhiều hơn 8 dặm với mỗi giờ sạc mức2",
    "graph.speedOfCharge.mphLevelTwoChargerThreeCars": "BMW đi được nhiều dặm nhất với mỗi giờ sạc mức 2",
    "graph.speedOfCharge.thirtyFastChargeThreeCars": "BMW đi được nhiều dặm nhất với mỗi 30 phút sạc nhanh",
    "graph.speedOfCharge.subTitleThreeCars": "{car}{subTitleText}",
    "graph.speedOfCharge.mphLevelTwoChargerTitle": "Lượng Điện Tiêu Thụ Mỗi Giờ Sạc Mức 2",
    "graph.speedOfCharge.fastChargerTitle": "Lượng Điện Tiêu Thụ Mỗi 30 Phút Sạc Nhanh",
    "graph.electricRange.subTitleThreeCars": "BMW có Quãng Đường Đi dài nhất Cho Một Lần Sạc",
    "graph.electricRange.subTitle": "Quãng đường đi được cho một lần sạc của BMW ít hơn 41 dặm",
    "": "Quãng đường đi được cho một lần sạc của BMW nhiều hơn 20 dặm",
    "graph.electricRange.gasolineRange": "Quãng Đường Đi khi Dùng Xăng",
    "graph.electricRange.assumption": "Quãng đường đi dựa trên Ước Tính của EPA",
    "graph.monthlyCostToFill.subTitle": "Chi phí đổ xăng hàng tháng của BMW mắc hơn $5",
    "": "Chi phí đổ xăng hàng tháng của BMW rẻ hơn $5",
    "graph.monthlyCostToFill.subTitleThreeCars": "BMW là xe có chi phí đổ xăng hàng tháng rẻ nhất",
    "graph.costToBreakdown": "Chi Phí Hư Hỏng",
    "graph.costToBreakdown.subTitle": "So sánh chi phí tích lũy trọn đời của BMW và AUDI",
    "graph.costToBreakdown.subTitleThreeCars": "So sánh chi phí tích lũy trọn đời của BMW, AUDI và TELSA",
    "graph.costToBreakdown.note": "Lưu ý: Sơ đồ huề vốn giả định mua xe tiền mặt.",
    "homepage.incentives.title": "Xem các khích lệ và tín thuế khả dĩ cho xe điện",
    "homepage.incentives.subTitle": "Xem quý vị có thể tiết kiệm bao nhiêu tiền khi đi xe điện cho dù quý vị mua hay thuê xe. Các khích lệ tùy vào nơi quý vị cư ngụ.",
    "homepage.incentives.exporeIncentives": "XEM CÁC KHÍCH LỆ",
    "compareVehicles.subTitle": "Xem Tất Cả Xe",
    "compareVehicles.selectFuel": "Chọn Loại Nhiên Liệu",
    "compareVehicles.selectMake": "Chọn Hiệu",
    "compareVehicles.selectModel": "Chọn Mẫu",
    "compareVehicles.pickTwo": "Chọn ít nhất 2 xe để so sánh",
    "compareVehicles.disclaimer": "Không phải tất cả thương hiệu/mẫu xe đều có sẵn.",
    "evs.welcomeSub": "So sánh xe điện về quãng đường đi trong một lần sạc, giá, hoặc Điểm Phù Hợp với quý vị. Bấm vào xe điện để biết thêm thông tin chi tiết, bao gồm tổng chi phí so với xe xăng tương tự.",
    "evs.buttonMatchScoreAndFilters": "Điểm Phù Hợp và Lọc",
    "evs.matchScoreAndFilters": "Xác Định Điểm Phù Hợp rồi Lọc",
    "evs.matchScoreOptions": "Xác Định Điểm Phù Hợp",
    "evs.roundTripCommute": "Đường Đi Làm Hai Chiều",
    "evs.budgetAfterIncentives": "Chi Phí sau Khích Lệ",
    "evs.minSeats": "Số Chỗ Ngồi Tối Thiểu",
    "evs.seats": "chỗ ngồi",
    "evs.homeChargingAvailability": "Có Thể Sạc Tại Nhà",
    "evs.homeChargingAvailabilityTooltip": "Sẽ có nhiều xe điện thích hợp hơn nếu quý vị có thể sạc nhanh tại nhà.",
    "evs.noCharging": "Không Sạc",
    "evs.levelOne": "Mức 1",
    "evs.levelTwo": "Mức 2",
    "evs.helpMeChoose": "Giúp Tôi Chọn",
    "evs.chargingAvailability": "Có Thể Sạc",
    "evs.chargercard.chargingtime.na": "Không áp dụng",
    "evs.disclaimer": "Xe hiển thị không phản ánh tình trạng xe có sẵn hiện tại. SVCE không chứng nhận hoặc khuyến cáo bất cứ loại xe hoặc nơi sản xuất xe cụ thể nào.",
    "chargingWizard.carOvernight": "Quý vị đậu xe ở đâu qua đêm?",
    "chargingWizard.parkingGarage": "Nhà xe riêng hoặc bãi đậu xe",
    "chargingWizard.onTheStreet": "Trên đường phố",
    "chargingWizard.publicParking": "Bãi đậu xe công cộng",
    "chargingWizard.electricianInstall": "Thợ điện có được lắp trạm sạc không?",
    "chargingWizard.overnightParking": "Quý vị có thể đậu xe qua đêm ở nơi có trạm sạc không?",
    "chargingWizard.powerOutlet": "Có lỗ cắm thông thường gần nơi quý vị đậu xe không?",
    "chargingWizard.chargingAvailability": "có thể sạc",
    "chargingWizard.noChargingAvailability": "không thể sạc",
    "chargingWizard.youWouldHave": "quý vị có {level 2 charging availability}",
    "chargingWizard.useThisValue": "Dùng giá trị này",
    "evfilter": "Lọc",
    "evfilter.fuel": "Nhiên liệu",
    "evfilter.fuelTooltip": "Xe chạy điện hoàn toàn chỉ dùng điện. Xe xăng chạy điện có thể dùng điện và xăng.",
    "evfilter.type": "Kiểu dạng",
    "yes": "Có",
    "no": "Không",
    "evCard.electricRange": "Quãng Đường Đi Cho Một Lần Sạc",
    "evCard.totalRange": "Tổng Quãng Đường Đi",
    "evCard.matchScore": "ĐIỂM PHÙ HỢP",
    "evCard.seeDetails": "Xem Thông Tin Chi Tiết",
    "evCard.seeElectricVehicle": "Xem Xe Diện",
    "evSort.title": "Xếp theo",
    "evSort.matchScore": "Điểm Phù Hợp",
    "evSort.electricRange": "Quãng Đường Đi Cho Một Lần Sạc",
    "evSort.priceLtH": "Giá: Từ thấp đến cao",
    "evSort.priceHtL": "Giá: Từ cao đến thấp",
    "evSort.alphabetical": "Trật tự bảng chữ cái",
    "pricePanels.cash": "Tiền mặt",
    "pricePanels.loan": "Tiền vay",
    "pricePanels.lease": "Thuê mướn",
    "pricePanels.loanPayment": "Trả tiền vay",
    "pricePanels.downPayment": "Tiền cọc",
    "pricePanels.leasePayment": "Trả tiền thuê",
    "pricePanels.perMonth": "/ tháng",
    "pricePanels.downPaymentDescription": "10% MSRP cộng thuế",
    "pricePanels.firstLeasePayment": "TIỀN THUÊ TRẢ LẦN ĐẦU",
    "pricePanels.incentivesForLease": "Khích Lệ Khi Thuê",
    "pricePanels.seeAllIncentives": "Xem Tất Cả Khích Lệ",
    "pricePanels.seeAllCosts": "Xem Tất Cả Chi Phí",
    "ev.jumbotron.viewAllCars": "XEM TẤT CẢ XE",
    "ev.jumbotron.seeReviews": "xem các bài đánh giá",

    "ev.carDetails.fastChargingTooltip": "Tốc Độ Sạc Nhanh DC:",
    "ev.carDetails.levelTwoChargerSpeedTooltip": "Tốc Độ Sạc Mức 2:",
    "ev.carDetails.batterySizeTooltip": "1 kilowatt giờ (kWh) là đủ để đi từ 1 đến 4 dặm, tùy loại xe. Một pin máy điện toán xách tay điển hình có thể có chưa đến 0.1 kWh.",
    "ev.carDetails.treesPlanted": "Cây Trồng",
    "ev.carDetails.gasolineSaved": "Lượng Xăng Tiết Kiệm",
    "ev.carDetails.compareText": "Chúng tôi chọn BMW do nó tương tự AUDI về nhãn hiệu, kích cỡ, và giá cả .",
    "ev.cardetails.calc.dcRate": "Giá Sạc Nhanh DC (DC Fast Charging, hay DCFC)",
    "ev.cardetails.calc.EPA": "Đánh Giá Hiệu Quả EPA",
    "ev.cardetails.calc.chargingRate": "Giá Sạc",
    "ev.cardetails.calc.vehicleBatteryCapacity": "Dung Lượng Pin Xe",
    "ev.cardetails.calc.maxACIntake": "Lượng Nạp AC Tối Đa của Xe",
    "ev.cardetails.calc.lvlTwoChargingRate": "Giá Sạc Mức 2",
    "ev.cardetails.calc.minOflvl2AC": "Giá Sạc Tối Thiểu Mức 2 và Lượng Nạp AC Tối Đa của Xe",
    "ev.cardetails.calc.lvl2FullCharge": "Thời Gian Sạc Đầy cho Mức 2",
    "ev.cardetails.calc.milesDrivenPerYr": "Số dặm đi mỗi năm",
    "ev.cardetails.calc.userInput": "Đầu Vào Người Dùng",
    "ev.cardetails.calc.milesPerGallon": "Số Dặm Đi cho Mỗi Ga Lông (Miles Per Gallon, hay MPG)",
    "ev.cardetails.calc.mpg": "dặm/ga lông",
    "ev.cardetails.calc.portionElectric": "Phần Chạy Điện",
    "ev.cardetails.calc.portionElectricSource": "Đầu vào người dùng. Đối với xe hybrid, ước tính số dặm đi dùng pin thay cho xăng.",
    "ev.cardetails.calc.gallonsUsed": "Số ga lông dùng",
    "ev.cardetails.calc.gallonsUsedFormula": "Số dặm đi * (1 – Phần Chạy Điện) / MPG",
    "ev.cardetails.calc.gallonsSaved": "Số Ga Lông Tiết Kiệm",
    "ev.cardetails.calc.gallonsSavedFormula": "Số ga lông dùng cho Xe Xăng Tương Tự - Số ga lông dùng cho Xe Điện",
    "ev.cardetails.calc.electricEmissions": "Lượng Khí Thải của Xe Điện tính bằng Cân Anh",
    "ev.cardetails.calc.electricEmissionsLbsMwh": "Lượng Khí Thải của Xe Điện tính bằng Cân Anh /MWh",
    "ev.cardetails.calc.EIA": "Ban Thông Tin Năng Lượng Hoa Kỳ",
    "ev.cardetails.calc.gasolineEmissionsYr": "Lượng Khí Thải của Xe Xăng tính bằng số cân Anh CO2/năm",
    "ev.cardetails.calc.gasolineEmissions": "Lượng Khí Thải của Xe Xăng tính bằng số cân Anh với mỗi Ga lông",
    "ev.cardetails.calc.emissionsReudctions": "Giảm Khí Thải",
    "ev.cardetails.calc.lbsCo2PerYr": "số cân Anh CO2 mỗi năm",
    "ev.cardetails.calc.gasolineEmissionsLbs": "Lượng Khí Thải của Xe Xăng tính bằng cân Anh",
    "ev.cardetails.calc.arborDayFoundation": "Hiệp Hội Arbor Day Foundation",
    "ev.cardetails.calc.emissionsPerTree": "Lượng Khí Thải Giảm Bớt với mỗi Cây",
    "ev.cardetails.calc.electricityEmissions": "Lượng Khí Thải khi dùng điện tính bằng CO2 cân Anh/MWh",
    "ev.usedCarTitle": "Xe BMW cũ",
    "ev.cardetails.electricRange": "Để tính mức sụt giảm của pin, quãng đường đi cho một lần sạc tính ở đây được ước tính dựa trên tuổi xe và kiểu lái xe/sạc bình thường.",
    "ev.carDetails.usedBatterySizeTooltip": "1 kilowatt giờ (kWh) là đủ để đi từ 1 đến 4 dặm, tùy loại xe. Để tính mức sụt giảm của pin với XE ĐIỆN cũ, kích cỡ pin tính ở đây được ước tính dựa trên tuổi xe và kiểu lái xe/sạc bình thường.",
    "ev.purchaseMethod": "Cách Mua",
    "ev.purchaseMethodToolTip": "Khi thuê xe, quý vị phải trả lại xe khi hết thời gian thuê. Khi vay mua xe, quý vị được giữ xe khi trả hết tiền vay.",
    "ev.cash": "Tiền mặt",
    "ev.loan": "Tiền vay",
    "ev.lease": "Thuê mướn",
    "ev.milesDrivenAnnually": "Số Dặm Đi Mỗi Năm",
    "ev.electricPortion": "Phần Chạy Điện với PHEV",
    "ev.electricPortionTooltip": "Xe Xăng Chạy Điện Cắm Sạc (Plug-In Hybrid Electric Vehicle, hay PHEV) có thể dùng cả điện và xăng. Thường có lợi hơn khi dùng điện.",
    "ev.yearsOwnership": "Số Năm Sở Hữu/Thuê",
    "ev.interestRate": "Lãi suất",
    "assumption.salesTaxPercent": "",
    "assumption.salesTax": "Thuế bán hàng",
    "assumption.gasolinePrice": "Giá xăng",
    "assumption.downPayment": "Tiền cọc",
    "assumption.electricityRate": "Giá điện",
    "assumption.downPaymentValue": "10% MSRP + Thuế",
    "ev.compareGasVehicle": "So sánh BMW với xe xăng tương tự, AUDI",
    "ev.compareOtherVehicles": "SO SÁNH CÁC XE KHÁC",
    "ev.incentives.title": "Có tín thuế và chương trình giảm giá khả dĩ lên đến $100 cho BMW",
    "ev.reviews": "Đánh giá trên mạng lưới",
    "chargingMap.updateZipcode": "Cập Nhật Bưu Chánh",
    "chargingMap.errorZipcode": "Báo lỗi! {workingZipcode} không phải bưu chánh hợp lệ",
    "chargingMap.showFullListOfStations": "Danh Sách Đầy Đủ Các Trạm",
    "chargingMap.public": "Trạm Công Cộng",
    "chargingMap.businessGovernment": "Do doanh nghiệp hoặc chính phủ lắp",
    "chargingMap.highPowerStations": "Trạm công suất cao",
    "chargingMap.DCChargers": "Sạc nhanh DC hoặc hệ thống sạc siêu tốc",
    "chargingMap.otherSttations": "Các loại trạm khác",
    "chargingMap.privateStations": "Trạm tư nhân",
    "chargingMap.searchDealers": "Tìm Đại Lý Đủ Tiêu Chuẩn",
    "chargingMap.chargingStationCluster": "Nhóm Trạm Sạc",
    "chargingMap.clusterOfChargingStations": "Nhóm Trạm Sạc",
    "chargingMap.disclaimer": "Dữ liệu do Trung Tâm Dữ Liệu Nhiên Liệu Thay Thế cung cấp. Bản đồ có thể không phản ánh đúng các trạm sạc hiện có.",


    "dealers.brands": "Nhãn hiệu",
    "dealers.allBrands": "Tất cả hiệu",
    "dealers.allDealers": "Tất cả đại lý",
    "dealers.contact": "LIÊN LẠC",
    "dealers.website": "MẠNG LƯỚI",
    "dealers.findDealers": "Tìm đại lý",
    "incentives.title": "Khích Lệ Đi Xe Điện",
    "incentives.subTitle": "Quý vị có thể đủ tiêu chuẩn được một số khích lệ, bao gồm giảm giá, tín thuế, và nhiều lợi ích khác. Các khích lệ tùy theo nơi quý vị sống.",
    "incentives.single": "Độc thân",
    "incentives.married": "Đã kết hôn",
    "incentives.headOfHousehold": "Chủ Hộ",
    "incentives.vehicleType": "Loại xe",
    "incentives.vehicleTypeTooltip": "Một số khích lệ tùy vào loại xe quý vị mua.",
    "incentives.tradeIn": "Đổi xe",
    "incentives.income": "Tình trạng lợi tức và thuế",
    "incentives.incomeTooltip": "Tiêu chuẩn được khích lệ và số tiền quý vị có thể được thường phụ thuộc vào lợi tức của quý vị. Khách hàng có lợi tức thấp hơn thường đủ tiêu chuẩn được giảm giá nhiều hơn.",
    "incentives.clunkerNone": "Quý vị được một số khích lệ khi trả lại xe cũ, còn gọi là xe ô nhiễm.",
    "incentives.clunkertoolTip": "Quý vị được một số khích lệ khi trả lại xe cũ, còn gọi là xe ô nhiễm. Cụ thể là các xe",
    "incentives.clunkertoolTipContd": "ở vùng quý vị sinh sống. Đòi hỏi về tiêu chuẩn thay đổi theo loại khích lệ; xin xem thông tin chi tiết về chương trình.",
    "incentives.householdSize": "Số người trong gia đình",
    "incentives.householdIncome": "Lợi tức gia hộ",
    "incentives.planClunker": "Quý vị có dự định trả xe ô nhiễm không?",
    "incentives.location": "Địa điểm",
    "incentives.locationTooltip": "Nhiều khích lệ tùy vào nơi quý vị sống. Nhập bưu chánh nơi quý vị ở để biết có những khích lệ nào nơi đó.",
    "incentives.taxFilingStatus": "Tình Trạng Khai Thuế",
    "incentives.whichVehicle": "Quý vị dự định mua loại xe nào?",
    "incentives.incentiveEligibility": "Cập Nhật Tiêu Chuẩn Được Khích Lệ",
    "homeChargers.subText": "Chọn loại xe quý vị muốn để biết cụ thể thời gian sạc đầy pin.",
    "homeChargers.wallMounted": "Treo tường",
    "homeChargers.portable": "Dễ mang theo",
    "homeChargers.price": "Mức giá",
    "homeChargers.cordLength": "Chiều dài dây",
    "homeChargers.wifi": "Kết nối WiFi",
    "homeChargers.socket": "Ổ cắm",
    "homeChargers.socketTooltip": "Các mẫu bộ sạc khác nhau có dạng phích cắm khác nhau. Các dạng phích cắm này cần phù hợp với lỗ cắm trên tường của quý vị. Nếu quý vị không biết loại nào phù hợp nhất cho nhà/nhà xe của quý vị, thì hỏi thợ điện đủ tiêu chuẩn.",
    "homeChargers.cordLengthLtH": "Chiều dài dây: Từ thấp lên cao",
    "homeChargers.cordLengthHtL": "Chiều dài dây: Từ cao xuống thấp",
    "homeChargersCard.buy": "Thông Tin Chi Tiết & Mua",
    "homeChargersCard.beforeIncentives": "Trước khi có khích lệ",
    "homeChargersCard.socket": "Ổ cắm",
    "homeChargersCard.teslaWarning": "Bộ sạc này chỉ dùng cho các xe Tesla",
    "homeChargers.cable": "Dây cáp dài {length} bộ",
    "homeChargers.hardwired": "Cáp gắn dây",
    "homeChargers.toFullCharge": "để sạc đầy",
    "homeChargers.wifiTooltip": "Một số bộ sạc có khả năng kết nối với hệ thống WIFI của nhà quý vị để dùng thêm các tính năng.",
    "chargercard.selectMakeModel": "*Xin chọn hiệu và mẫu xe để xem thời gian sạc.",
    "electricians.residential": "Tư gia",
    "electricians.commercial": "Thương mại",
    "electricians.industrial": "Công nghiệp",
    "electricians.type": "Loại thợ điện",
    "electricians.searchElectricians": "Tìm thợ điện đủ tiêu chuẩn",
    "tabbed-maps-dealer-catalog-tab": "Đại Lý",
    "tabbed-maps-all-stations-tab": "Trạm Sạc",
    "tabbed-maps-electricians-tab": "Thợ Điện",
    "tabbed-maps-route-tab": "Đưa Tuyến Đường Lên Bản Đồ",
    "ev.showSources.electricityRate": "Giá điện được tính trung bình cho bốn tháng trong mùa hè không cao điểm và tám tháng trong mùa đông không cao điểm. Xin bấm vào {website} để tìm hiểu thêm.",
    "ev.showSources.portionElectricSource": "Đầu vào người dùng. Với xe hỗn hợp, ước tính số dặm đi dùng pin điện thay cho xăng.",
    "ev.showSources.resaleValue": "Giá Bán Lại",
    "ev.showSources.resaleValueSource": "Theo giá MSRP của xe và quãng đường đi 100,000 dặm trong vòng đời xe, giả sử khấu hao theo cấp số nhân mũ âm thì giá bán lại là 60% khi xe đi 36,000 dặm",
    "ev.showSources.totalVehicleCost": "Tổng Chi Phí Xe",
    "ev.showSources.cashFormula": "MSRP Xe * (1 + Thuế Bán) – Khích Lệ - Giá Bán Lại",
    "ev.showSources.assumption": "Giả định:",
    "ev.showSources.loanAmount": "Lượng Tiền Vay",
    "ev.showSources.loanFormula": "MSRP * (1 + Thuế Bán) - Tiền Cọc",
    "ev.showSources.monthlyPayments": "Tiền Trả Hàng Tháng",
    "ev.showSources.monthlyPaymentsSource": "Hàm tài chánh của Lượng Tiền Vay, Lãi Suất, và Số Tháng Sở Hữu",
    "ev.showSources.monthsOfOwnership": "Số Tháng Sở Hữu",
    "ev.showSources.totalMonthlyPayments": "Tổng Tiền Trả Hàng Tháng",
    "ev.showSources.totalMonthlyPaymentsSource": "Tiền Trả Hàng Tháng * Số Tháng Sở Hữu",
    "ev.showSources.totalLoanFormula": "Tiền Trả Trước + Tổng Tiền Trả Hàng Tháng – Khích Lệ - Giá Bán Lại",
    "ev.showSources.capitalizedIncentives": "Khích Lệ Theo Vốn",
    "ev.showSources.capitalizedIncentivesSource": "Khích lệ theo vốn khi thuê",
    "ev.showSources.capitalizedCost": "Chi Phí Theo Vốn",
    "ev.showSources.capitalizedCostSource": "MSRP - Tiền Trả Trước - Khích Lệ Theo Vốn",
    "ev.showSources.monthlyDepreciationCost": "Chi Phí Khấu Hao Hàng Tháng",
    "ev.showSources.monthlyDepreciationCostSource": "(Chi Phí Theo Vốn - Giá Bán Lại) / Số Tháng Sở Hữu",
    "ev.showSources.monthlyFinancingCost": "Chi Phí Tài Chánh Hàng Tháng",
    "ev.showSources.monthlyFinancingCostSource": "(Chi Phí Theo Vốn + Giá Bán Lại) * Lãi Suất",
    "ev.showSources.moneyFactor": "Lãi Suất",
    "ev.showSources.moneyFactorSource": "Tương đương đầu vào người dùng 3.50%",
    "ev.showSources.monthlyLeasePayment": "Tiền Thuê Trả Hàng Tháng",
    "ev.showSources.monthlyLeasePaymentSource": "(Chi Phí Khấu Hao Hàng Tháng + Chi Phí Tài Chánh Hàng Tháng) * (1 + Thuế Bán)",
    "ev.showSources.firstLeasePayment": "Tiền Thuê Trả Lần Đầu",
    "ev.showSources.firstLeasePaymentSource": "Tiền Cọc + Tiền Thuê Trả Hàng Tháng",
    "ev.showSources.nonCapLeaseIncentives": "Khích Lệ Không Theo Vốn Khi Thuê",
    "ev.showSources.nonCapLeaseIncentivesSource": "Khích lệ không tính vào chi phí theo vốn khi thuê",
    "ev.showSources.leaseForumla": "Tiền Thuê Trả Lần Đầu + (Số Tháng Sở Hữu - 1) * (Tiền Thuê Trả Hàng Tháng) - Khích Lệ Không Theo Vốn Khi Thuê",
    "ev.showSources.vehicleKWh": "Số kWh của xe mỗi 100 dặm",
    "ev.showSources.EPAEfficiency": "Đánh Giá Hiệu Quả EPA",
    "ev.showSources.dollarPerMileDriven": "$/100 dặm lái xe",
    "ev.showSources.dollarPerMileDrivenSource": "Chi Phí Điện * Số kWh của xe mỗi 100 dặm",
    "ev.showSources.lifetimeMiles": "Số Dặm Đi Trong Vòng Đời",
    "ev.showSources.totalElectricityCost": "Tổng Chi Phí Điện",
    "ev.showSources.totalElectricityCostSource": "$/100 dặm lái xe * Số Dặm Đi Trong Vòng Đời * 30/ 100",
    "ev.showSources.gasolineCost": "Chi Phí Xăng",
    "ev.showSources.vehicleMPG": "MPG của xe (số dặm mỗi ga lông)",
    "ev.showSources.totalGasolineCost": "Tổng Chi Phí Xăng",
    "ev.showSources.totalGasolineCostSource": "Chi Phí Xăng * Số Dặm Đi Trong Vòng Đời * 30/ MPG",
    "ev.showSources.maintenancePerMile": "Bảo trì cho mỗi dặm chạy xăng",
    "ev.showSources.maintenancePerMileSource": "Ngoại suy chi phí bảo trì trung bình là $0.06/dặm, dựa trên MSRP của xe so với MSRP trung bình $37,000",
    "ev.showSources.maintenanceCostReduction": "Giảm Chi Phí Bảo Trì Xe Điện",
    "ev.showSources.maintenanceCostReductionSource": "",
    "ev.showSources.maintenancePerMileElectricity": "Bảo trì cho mỗi dặm khi chạy điện",
    "ev.showSources.maintenancePerMileElectricitySource": "Bảo trì cho mỗi dặm chạy xăng * Hệ Số Giảm Bảo Trì Xe Điện",
    "ev.showSources.blendedMaintenanceCostPerMile": "Chi phí bảo trì hỗn hợp mỗi dặm",
    "ev.showSources.blendedMaintenanceCostPerMileSource": "Phần Chạy Điện tính cho Bảo Trì mỗi dặm chạy xăng và chạy điện",
    "ev.showSources.totalMaintenanceCost": "Tổng Chi Phí Bảo Trì",
    "ev.showSources.totalMaintenanceCostSource": "Chi phí bảo trì mỗi dặm * Số Dặm Đi Trong Vòng Đời",
    "ev.showSources.averageInsurancePerYear": "Bảo Hiểm Trung Bình mỗi Năm",
    "ev.showSources.insurancePerYear": "Bảo Hiểm mỗi Năm",
    "ev.showSources.insurancePerYearSource": "Ngoại suy chi phí bảo hiểm trung bình ở CA, dựa trên MSRP của xe so với MSRP trung bình $37,000",
    "ev.showSources.yearsOfOwnership": "Số Năm Sở Hữu",
    "ev.showSources.totalInsuranceCost": "Tổng Chi Phí Bảo Hiểm",
    "ev.showSources.totalInsuranceCostSource": "Phí bảo hiểm mỗi năm * Số Năm Sở Hữu",
    "here": "đây",
    "sources": "Nguồn tham khảo",

    "seeFullListOfStations": "Xem {scroll}",
    "fullListOfStations": "danh sách đầy đủ các trạm sạc",
    "numOfStations": "# các trạm sạc",
    "publicChargingStationBasics": "Hiểu Biết Căn Bản về Trạm Sạc Công Cộng",
    "publicChargingFAQ": "Những Câu Hỏi Thường Gặp về Trạm Sạc Công Cộng",
    "differentTypesOfChargingStationsQuestion": "Có những loại trạm sạc nào?",
    "thereAreThreeLevels": "Có ba mức sạc xe điện với tốc độ sạc khác nhau: {levelOne}, {levelTwo} và {dcfc}. Thời gian cần để sạc cho xe điện của quý vị tùy vào kích cỡ pin xe, tốc độ sạc xe quý vị muốn và tốc độ trạm sạc.",
    "dcfcFastCharge": "Sạc Nhanh DC",
    "levelOneChargingDescription": "Một giờ gắn vào bộ sạc {levelOne} (lỗ cắm hộ gia đình theo chuẩn Mỹ) sẽ cho xe dùng điện hoàn toàn (battery electric vehicle, hay BEV) hoặc xe xăng chạy điện hỗn hợp (plug-in hybrid electric vehicle, hay PHEV) chạy thêm được khoảng 2-10 dặm đường. Bộ sạc mức này phù hợp nhất cho sạc qua đêm khi quý vị không dùng xe, hoặc cho PHEV có pin nhỏ hơn và có thể dùng động cơ xăng của xe khi hết pin.",
    "levelTwoChargingDescription": "{levelTwo} có tốc độ sạc nhanh hơn nhiều so với Mức 1 và hiện là lựa chọn phổ biến nhất ở các trạm sạc công cộng. Một giờ sạc Mức 2 cho hầu hết BEV và PHEV chạy thêm khoảng 20-25 dặm.",
    "dcfcChargingDescription": "{dcfc} (DC Fast Chargers, hay DCFC) là loại bộ sạc nhanh nhất dùng công cộng hiện nay. Tài xế BEV có thể tin tưởng đi thêm được 150 dặm đường sau một giờ sạc. Với hầu hết BEV, tài xế có thể tin tưởng được phục hồi gần 80% tổng quãng đường chỉ trong 30 phút sạc. Điều quan trọng cần lưu ý là PHEV (và một số mẫu cũ BEV) không phù hợp với trạm DCFC.",
    "teslaChargingDescription": "Các trạm sạc siêu tốc của Tesla hầu như có tốc độ tương đương với DCFC, nhưng chỉ các xe Tesla có thể sạc ở các trạm sạc công cộng mang nhãn hiệu Tesla. Xem dưới đây để biết thêm về các đầu nối sạc phù hợp, và {browseElectricVehicles} để biết thêm thông tin về các hiệu và mẫu xe cụ thể.",
    "publicChargingEVQuestion": "Làm cách nào để chọn trạm sạc công cộng phù hợp với xe điện của mình?",
    "everyEVCompatible": "Tất cả xe điện chạy trên đường hiện nay ở Hoa Kỳ đều phù hợp với một trong ba đầu nối sạc tiêu chuẩn: SAE J1772, CHAdeMO và Tesla.",
    "publicChargerOutlets": "Lỗ Cắm Bộ Sạc Công Cộng",
    "saeDescription": "SAE J1772 được thiết kế cho bộ sạc Mức 1 và Mức 2, và có thể phù hợp với DCFC khi có trang bị phích cắm CCS Combo.",
    "chademoDescription": "Đầu nối CHAdeMO phù hợp với bộ sạc Mức 1, Mức 2 hoặc DCFC có đầu nối thông thường.",
    "teslaDescription": "Tesla có thể dùng hệ thống trạm sạc Siêu tốc riêng để sạc cho xe của hãng bằng đầu nối tiêu chuẩn của Tesla nhưng cần dùng bộ chuyển đổi để sạc ở các trạm SAE J1772 hoặc CHAdeMO.",
    "dcfcChargerDescription": "Trước khi quý vị dùng Bộ Sạc Nhanh DC, thì cần biết loại đầu nối của xe điện quý vị. Một số trạm sạc DCFC có cả hai loại đầu nối CHAdeMO và SAE J1772. Hệ thống sạc Siêu tốc của Tesla không phù hợp với xe các hãng khác.",
    "payingForChargingQuestion": "Tôi có phải trả tiền khi dùng bộ sạc công cộng không? Nếu có, thì cụ thể thế nào?",
    "payingForChargingAnswer": "Một số trạm sạc công cộng miễn phí, còn một số đòi hỏi tài xế trả tiền. Trạm có thể cho phép người dùng cà thẻ tín dụng ngay tại phích cắm hoặc cung cấp các gói ghi danh trả trước hàng tháng, thường là thông qua một ứng dụng trên điện thoại của tài xế. Vào đường nối trong bảng Hệ Thống Trạm Sạc Công Cộng Quốc Gia để biết thêm thông tin về các lựa chọn của quý vị.",
    "publicChargingEtiquette": "Phép lịch sự ở trạm sạc công cộng",
    "publicChargingRules": "Hầu hết người dùng bộ sạc công cộng đều làm theo “các quy tắc giao thông” rất đơn giản để bảo đảm tất cả mọi người đều có kinh nghiệm tốt. Một số thực hành tốt nhất là:",
    "moveCarAnswer": "Khi sạc xong, quý vị phải chạy xe của mình ra khỏi điểm sạc càng sớm càng tốt để những người khác có thể sạc.",
    "unplugAnswer": "Không rút phích cắm của người khác để cắm vào xe mình.",
    "topOffAnswer": "Hãy sạc thêm khi có thể làm được, chớ đừng chờ khi buộc phải sạc. Nếu quý vị sạc thêm được một chút ở những nơi khác nhau (đặc biệt khi quý vị không ở trong xe – như vào cửa hàng tạp hóa hoặc trung tâm mua sắm), thì có thể tránh phải dừng lại lâu để sạc khi pin gần cạn kiệt.",
    "bevToFull": "Sạc cho BEV từ hết kiệt pin đến khi đầy: {about} {time} {hours}",
    "about": "~",
    "hours": "giờ",
    "phevToFull": "Sạc cho PHEV từ hết kiệt pin đến khi đầy: {about} {time} {hours}",
    "levelOneDescription": "Dùng tốt nhất khi xe không hoạt động trong nhiều giờ.",
    "levelTwoDescription": "Hầu hết có bộ sạc ở các trạm sạc công cộng để tăng thêm quãng đường khi đang di chuyển hoặc sạc nhanh tại nhà.",
    "dcfcDescription": "Có bộ sạc nhanh nhất, với hệ thống trạm sạc công cộng mở rộng.",
    "leastFast": "CHẬM HƠN",
    "faster": "NHANH HƠN",
    "fastest": "NHANH NHẤT",
    "milesOfRangePerHourCharge": "với mỗi giờ sạc",
    "chargingSpeedDisclaimer": "Các số liệu trên đây là cho xe điện dùng điện hoàn toàn (Battery Electric Vehicle, hay BEV) có dung lượng pin 62 kW và xe xăng chạy điện cắm sạc (Plug-in Hybrid Vehicle, hay PHEV) có dung lượng pin 14kW. Cả hai loại xe đều có đánh giá EPA là 31 kWh mỗi 100 dặm.",
    "nationalPublicChargingNetworks": "HỆ THỐNG TRẠM SẠC CÔNG CỘNG QUỐC GIA",
    "network": "Trong hệ thống",
    "stations": "Trạm Sạc",
    "levelTwoStations": "Trạm Sạc Mức 2",
    "dcfcStations": "Trạm Sạc DCFC",
    "costPerCharge": "Chi Phí Mỗi Lần Sạc",
    "levelTwoCostPerCharge": "Chi Phí Mỗi Lần Sạc Mức 2",
    "dcfcCostPerCharge": "Chi Phí Mỗi Lần Sạc DCFC",
    "mixOfKwhAndSession": "Tính gộp $/kWh và $/phiên, theo tiểu bang",
    "variesByLocation": "Thay đổi theo địa điểm",
    "dollarPerMinuteVaryWithPowerAndState": "$/phút, thay đổi theo mức công suất và theo tiểu bang",
    "flatFeePerHour": "Phí cố định - {cost}/giờ",
    "costPerHour": "{cost}/giờ",
    "costsPerHour": "{lowCost}/giờ - {highCost}/giờ",
    "dollarPerMinuteByState": "$/phút, theo tiểu bang",
    "free": "Miễn phí",
    "flatFeePerSession": "Phí cố định - $4.00/phiên",
    "dollarPerSession": "$7.50/phiên",
    "milesPerHourOfCharge": "{miles} dặm với mỗi giờ sạc",

    "header.exploreElectricCars": "Xem Các Loại Xe Điện",
    "header.browseElectricCars": "Tìm Xe Diện",
    "header.compareVehicles": "So Sánh Các Xe",
    "header.chargingOptions": "Các Lựa Chọn Sạc",
    "header.locatePublicChargingStations": "Tìm Trạm Sạc Công Cộng",
    "header.shopHomeChargers": "Mua Bộ Sạc Tại Nhà",
    "header.findElectricians": "Tìm Thợ điện",
    "header.discoverIncentives": "Xem các Khích Lệ",
    "header.moreSites": "Nhiều Địa Điểm hơn",

    "footer.havingTrouble": "Quý vị khó dùng mạng lưới này?",
    "footer.contact": "Xin liên lạc: support@zappyride.com",

    "ev.jumbotron.compare": "So Sánh",

    "homepage.incentives.legalDisclaimer": "Chương Trình Năng Lượng Sạch Thung Lũng Silicon (Silicon Valley Clean Energy, hay SVCE) lập ra mạng lưới này làm nguồn tham khảo cho khách hàng. SVCE không chứng nhận hoặc bảo đảm, và không liên quan đến bảo hành hay đại diện cho bất cứ người bán, nhà thầu, dịch vụ hay sản phẩm nào. SVCE không chịu trách nhiệm về bất cứ mất mát hay hư hỏng nào xảy ra hay liên quan đến người bán, nhà thầu, dịch vụ hoặc sản phẩm liên quan với mạng lưới này. Tất cả giao dịch của quý vị với người bán, nhà thầu hoặc bên thứ ba chỉ là giữa quý vị và người bán, nhà thầu hoặc bên thứ ba đó.",
    "homepage.locateChargingStations": "Tìm Các Trạm Sạc",

    "ev.carDetails.treeTooltip": "Một cây hấp thụ CO2 với tốc độ 28 lbs mỗi năm",
    "ev.carDetails.calc.emissionsSaved": "{CO2} lbs CO2 mỗi cây mỗi năm",
    "gasolinePrice": "Giá Xăng Dầu($/GAL)",
    "ev.electricityRateCalc": "Giá điện được tính trung bình cho bốn tháng trong mùa hè không cao điểm và tám tháng trong mùa đông không cao điểm. Xin bấm vào {website} để tìm hiểu thêm.",
    "ev.showSources.averageInsuranceCost": "Chi phí bảo hiểm trung bình ở {state}",
    "graph.speedOfCharge.lessThanFiftykW": "không thể sạc nhanh hơn 50kW",

    "publicCharingStationMap": "Bản đồ trạm sạc công cộng",
    "fullListOfDealers": "danh sách đầy đủ các đại lý",
    "evChargingSpeed": "Tốc độ sạc EV",
    "comingSoon": "Sắp có",
}

export default { ...VI, ...VI_CUSTOM }