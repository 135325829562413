import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";

import Select from "../../../../components/shared/InputElements/Select";

const SelectElectricityProvider = ({
  id = "electricity-provider",
  location,
  ...rest
}) => {
       const userPrefs = useContext(UserPrefsContext);

  let power_suppliers = [];
  if (location) {
    location.power_supplier.forEach((spot) => {
      if (spot.name === "DTE Energy") return;
      power_suppliers.push(spot.name);
    });
  }



  return (
    <Select
      id={id}
      isSpecial={true}
      value={userPrefs.get("electricityProvider").toString()}
      optionNames={power_suppliers.length >= 1 ? power_suppliers : ["N/A"]}
      optionValues={power_suppliers.length >= 1 ? power_suppliers : ["N/A"]}
       handler={(e) => userPrefs.set({ electricityProvider: e.target.value })}
    />
  );
};

export default SelectElectricityProvider;

SelectElectricityProvider.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
};
