import React from "react";
import "./Footer.scss";

import face from "../../../../client_customizations/assets/images/facebook.svg";
import twitter from "../../../../client_customizations/assets/images/twitter.svg";
import youtube from "../../../../client_customizations/assets/images/youtube.svg";
import insta from "../../../../client_customizations/assets/images/instagram.svg";
import tiktok from "../../../../client_customizations/assets/images/tiktok-white-icon.svg"
import pinterest from "../../../../client_customizations/assets/images/pinterest.svg"
import JDPower from "../../../../client_customizations/assets/images/JDPower-Logo.svg"
import PrivacyIcon from "../../../../client_customizations/assets/images/privacyoptions.svg"
import { FormattedMessage } from "react-intl";

const Footer = () => {
       return (
              <footer>
                     <div className="Footer" role="contentinfo">
                     <div className="footer-bottom">
                     <div className="footer-blocks">
                            <div className="footer-block">
                            <h3 className="footer-title">
                            <FormattedMessage
                                   id="footer.Resources"
                                   defaultMessage="Resources"
                                   description="Resources"
                            />
                            </h3>
                            <div className="footer-panel">
                            <div className="footer-column">
                                   <ul className="footer-list">
                                   <li className="footer-item">
                                   <a href="https://www.acuracertified.com/">
                                   <FormattedMessage
                                          id="footer.preOwned"
                                          defaultMessage="Certified Pre-Owned"
                                          description="Certified Pre-Owned"
                                   />
                                   </a>
                                   </li>
                                   <li className="footer-item">
                                   <a href="https://mygarage.honda.com/s/find-acura">
                                   <FormattedMessage
                                          id="footer.owners"
                                          defaultMessage="Owners"
                                          description="Owners"
                                   />
                                   </a>
                                   </li>
                                   <li className="footer-item">
                                   <a href="https://mygarage.honda.com/s/acuralink-marketing">
                                   <FormattedMessage
                                          id="footer.acuraLink"
                                          defaultMessage="AcuraLink"
                                          description="AcuraLink"
                                   />
                                   </a>
                                   </li>
                                   <li className="footer-item">
                                   <a href="https://shop.shiftgearracing.com/">
                                   <FormattedMessage
                                          id="footer.acuraMerchandise"
                                          defaultMessage="Acura Merchandise"
                                          description="Acura Merchandise"
                                   />
                                   </a>
                                   </li>
                                   </ul>
                            </div>
                            <div className="footer-column">
                                   <ul className="footer-list">
                                   <li className="footer-item">
                                   <a href="https://www.acura.com/contact-us">
                                   <FormattedMessage
                                          id="footer.contact"
                                          defaultMessage="Contact Us"
                                          description="Contact Us"
                                   />
                                   </a>
                                   </li>
                                   <li className="footer-item">
                                   <a href="https://www.acura.com/contact-us">
                                   <FormattedMessage
                                          id="footer.faq"
                                          defaultMessage="FAQ"
                                          description="FAQ"
                                   />
                                   </a>
                                   </li>
                                   <li className="footer-item">
                                   <a href="https://acura.americanhondafinance.com/s/<">
                                   <FormattedMessage
                                          id="footer.acuraFinancial"
                                          defaultMessage="Acura Financial Services"
                                          description="Acura Financial Services"
                                   />
                                   </a>
                                   </li>
                                   <li className="footer-item">
                                   <a href="https://www.acura.com/news-and-press">
                                   <FormattedMessage
                                          id="footer.news"
                                          defaultMessage="News & Press"
                                          description="News & Press"
                                   />
                                   </a>
                                   </li>
                                   <li className="footer-item">
                                   <a href="https://www.honda.com/careers">
                                   <FormattedMessage
                                          id="footer.careers"
                                          defaultMessage="Careers"
                                          description="Careers"
                                   />
                                   </a>
                                   </li>
                                   </ul>
                            </div>
                            <div className="footer-column">
                                   <ul className="footer-list">
                                   <li className="footer-item">
                                   <a href="https://automobiles.honda.com/mobility-assistance">
                                   <FormattedMessage
                                          id="footer.mobility"
                                          defaultMessage="Mobility Assistance Program"
                                          description="Mobility Assistance Program"
                                   />
                                   </a>
                                   </li>
                                   <li className="footer-item">
                                   <a href="https://www.acura.com/environment">
                                   <FormattedMessage
                                          id="footer.enviromental"
                                          defaultMessage=" Environmental Leadership Program"
                                          description=" Environmental Leadership Program"
                                   />
                                   </a>
                                   </li>
                                   <li className="footer-item">
                                   <a href="https://csr.honda.com/diversity/">
                                   <FormattedMessage
                                          id="footer.inclusion"
                                          defaultMessage="Inclusion & Diversity"
                                          description="Inclusion & Diversity"
                                   />
                                   </a>
                                   </li>
                                   </ul>
                            </div>
                            </div>
                            </div>
                            <div className="footer-block small">
                            <h3 className="footer-title">
                            <FormattedMessage
                                   id="footer.stayInformed"
                                   defaultMessage="Stay Informed"
                                   description="Stay Informed"
                            />
                            </h3>
                            <ul className="icons-row">
                            <li>
                                   <a href="https://twitter.com/Acura">
                                   <div className="circle">
                                   <img
                                   className="icon"
                                   alt="Acura Twitter"
                                   src={twitter}
                                   />
                                   </div>
                                   </a>
                            </li>
                            <li>
                                   <a href="https://www.facebook.com/Acura">
                                   <div className="circle">
                                   <img
                                   className="icon"
                                   alt="Acura Facebook"
                                   src={face}
                                   />
                                   </div>
                                   </a>
                            </li>
                            <li>
                                   <a href="https://www.instagram.com/acura">
                                   <div className="circle">
                                   <img
                                   className="icon"
                                   alt="Acura Instagram"
                                   src={insta}
                                   />
                                   </div>
                                   </a>
                            </li>
                            <li>
                                   <a href="https://www.youtube.com/user/Acura">
                                   <div className="circle">
                                   <img
                                   className="icon"
                                   alt="Acura Youtube"
                                   src={youtube}
                                   />
                                   </div>
                                   </a>
                            </li>
                            <li>
                                   <a href="https://www.pinterest.com/Acura/pins/follow//">
                                   <div className="circle">
                                   <img
                                   className="icon"
                                   alt="Acura Pinterest"
                                   src={pinterest}
                                   />
                                   </div>
                                   </a>
                            </li>
                            <li>
                                   <a href="https://www.tiktok.com/@acura">
                                   <div className="circle">
                                   <img
                                   className="icon smaller"
                                   alt="Acura Tiktok"
                                   src={tiktok}
                                   />
                                   </div>
                                   </a>
                            </li>
                            </ul>
                            {/* Will be needed for language update */}
                            {/* <div className="footer-block-language">
                            <h3 className="footer-title">
                                   <FormattedMessage
                                   id="footer.chooseLanguage"
                                   defaultMessage="Choose Your Language"
                                   description="Choose Your Language"
                                   />
                            </h3>
                            <div className="language">
                                   <FormattedMessage
                                   id="footer.english"
                                   defaultMessage="English"
                                   description="English"
                                   />
                            </div>
                            </div> */}
                            </div>
                     </div>
                     <div className="footer-legal">
                            <ul className="footer-legal-list">
                            <li className="footer-legal-list-item">
                            <a href="http://owners.acura.com/service-maintenance/recalls">
                                   <FormattedMessage
                                   id="footer.safetyRecalls"
                                   defaultMessage="Safety Recalls"
                                   description="Safety Recalls"
                                   />
                            </a>
                            </li>
                            <li className="footer-legal-list-item">
                            <a href="https://www.honda.com/privacy/connected-product-privacy-notice">
                                   <FormattedMessage
                                   id="footer.vehicleDataPrivacy"
                                   defaultMessage="Vehicle Data Privacy"
                                   description="Vehicle Data Privacy"
                                   />
                            </a>
                            </li>
                            <li className="footer-legal-list-item">
                            <a href="https://www.honda.com/privacy/privacy-notice">
                                   <FormattedMessage
                                   id="footer.privacy"
                                   defaultMessage="Privacy Notice"
                                   description="Privacy Notice"
                                   />
                            </a>
                            </li>
                            <li className="footer-legal-list-item">
                            <a href="https://www.acura.com/-/media/Files/Footer/Proposition-65-Consumer-Website-Statement-072518.pdf?cache-busting=1.1.1">
                                   <FormattedMessage
                                   id="footer.prop"
                                   defaultMessage="Prop 65 Info"
                                   description="Prop 65 Info"
                                   />
                            </a>
                            </li>
                            <li className="footer-legal-list-item">
                            <a href="https://www.honda.com/privacy/sms-terms">
                                   <FormattedMessage
                                   id="footer.sms"
                                   defaultMessage="SMS Terms"
                                   description="SMS Terms"
                                   />
                            </a>
                            </li>
                            <li className="footer-legal-list-item">
                            <a href="https://www.honda.com/privacy/your-privacy-choices" className="inline-footer-link">
                                   <FormattedMessage
                                   id="footer.yourPrivacy"
                                   defaultMessage="Your Privacy Choices"
                                   description="Your Privacy Choices"
                                   /><img src={PrivacyIcon} alt="California Consumer Privacy Act (CCPA) Opt-Out Icon" />
                            </a>
                            </li>
                            <li className="footer-legal-list-item">
                            <a href="https://www.honda.com/privacy/terms-and-conditions">
                                   <FormattedMessage
                                   id="footer.terms"
                                   defaultMessage="Terms & Conditions"
                                   description="Terms & Conditions"
                                   />
                            </a>
                            </li>
                            <li className="footer-legal-list-item">
                            <a href="https://www.acura.com/site-map">
                                   <FormattedMessage
                                   id="footer.siteMap"
                                   defaultMessage="Site Map"
                                   description="Site Map"
                                   />
                            </a>
                            </li>
                            </ul>
                            <div className="footer-cookie">
                            <p>
                            <FormattedMessage
                                   id="footer.thirdParty"
                                   defaultMessage=" We and third parties use cookies and similar tools to enhance
                            and track your experience on our Sites, conduct analytics,
                            and personalize marketing to you. By using the Sites, you
                            agree to our use of these technologies. For more information,
                            visit the {cookies} within our Privacy Notice. To
                            manage your Cookie Preferences, {click}"
                                   description="cable"
                                   values={{
                                   cookies: (
                                   <strong>
                                   <a
                                          className="thirdParty-links"
                                          href="https://www.honda.com/privacy/privacy-notice#cookies"
                                   >
                                          <FormattedMessage
                                          id="footer.cookies"
                                          defaultMessage="Cookies section"
                                          description="Cookies section"
                                          />
                                   </a>
                                   </strong>
                                   ),
                                   click: (
                                   <strong>
                                   <button className="thirdParty-links ot-sdk-show-settings">
                                          <FormattedMessage
                                          id="footer.clickHere"
                                          defaultMessage="click here"
                                          description="click here"
                                          />
                                   </button>
                                   </strong>
                                   ),
                                   }}
                            />
                            </p>
                            </div>
                            <p className="copyright">
                            &copy;&nbsp;
                            <FormattedMessage
                            id="footer.acura"
                            defaultMessage="2024 Acura"
                            description="2024 Acura"
                            />
                            </p>
                            <div className="logo">
                            <img alt="JD Logo" src={JDPower} />
                            </div>
                            <p className="disclaimer">
                            <FormattedMessage
                            id="footer.disclaimer"
                            defaultMessage="Figures shown on this page are estimates based on data provided by third-parties. This material is provided for informational purposes only and should not be relied on for tax, legal, or financial advice. Neither J.D. Power® nor Acura provides tax, legal or financial advice. You should independently verify the estimates provided here before engaging in any transaction."
                            description="Figures shown on this page are estimates based on data provided by third-parties. This material is provided for informational purposes only and should not be relied on for tax, legal, or financial advice. Neither J.D. Power® nor Acura provides tax, legal or financial advice. You should independently verify the estimates provided here before engaging in any transaction."
                            />
                            </p>
                     </div>
                     </div>
                     </div>
              </footer>
       );
};

export default Footer;
