import React, { useContext } from "react";
import PropTypes from "prop-types";

import SelectVehicleModelFilter from "../InputComponents/SelectVehicleModelFilter/SelectVehicleModelFilter";
import SelectVehicleTrimFilter from "../InputComponents/SelectVehicleTrimFilter/SelectVehicleTrimFilter";

import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import { FormattedMessage } from "react-intl";

const IncentiveVehicleOptions = ({ electricVehicles }) => {
  const userPrefs = useContext(UserPrefsContext);
  const makeOption = userPrefs.get("vehicleMakeFilter")
  const modelOption = userPrefs.get("vehicleModelFilter");


  return (
    <div
      className="MatchScoreOptions"
      style={{ borderBottom: "1px solid #CDCDCD", paddingBottom: "10px", marginBottom: "20px" }}
    >
      <p className="h2">
        <FormattedMessage
          id="evs.electricVehicleFilters"
          defaultMessage="Choose Your Vehicle"
          description="Choose Your Vehicle"
        />
      </p>
      <SelectVehicleModelFilter
        vehicles={electricVehicles}
        makeOption={makeOption}
      />
      <SelectVehicleTrimFilter
        vehicles={electricVehicles}
        makeOption={makeOption}
        modelOption={modelOption}
      />
    </div>
  );
};

export default IncentiveVehicleOptions;

IncentiveVehicleOptions.propTypes = {
  electricVehicles: PropTypes.array,
};
