const fetchVehicleIncentivesWithHandle = async (params) => {
  // Remove null params
  Object.keys(params).forEach(
    (key) => params[key] == null && delete params[key]
  );

  let url = new URL(
    `${process.env.REACT_APP_EV_INFO_API_HOST}/vehicles/${params["vehicle_handle"]}`
  );

//   delete params["vehicle_handle"];
  let searchParams = new URLSearchParams(params);

  url.search = searchParams;

  const response = await window.fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`,
    },
  });

  if(!response.ok) return;

  const json = await response.json();
  const incentives = json.vehicle.incentives.filter(incentive => {
       return incentive.available && incentive.evaluation.eligibility === "eligible"
   });

  return incentives;
};

export default fetchVehicleIncentivesWithHandle;
