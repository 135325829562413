import cloneDeep from "lodash/cloneDeep";

// Primary sort on grantor, secondary sort on grant name
const Private = {
  alphabeticalSort: function (incentives, direction) {
    return incentives?.sort((a, b) => {
      let name = (incentive) => `${incentive.grantor}`;
      let sub_name = (incentive) => `${incentive.name}`;
      switch (direction) {
        case "asc":
          return name(a).localeCompare(name(b)) === 0
            ? sub_name(a).localeCompare(sub_name(b))
            : name(a).localeCompare(name(b));
        case "desc":
          return name(b).localeCompare(name(a)) === 0
            ? sub_name(b).localeCompare(sub_name(a))
            : name(b).localeCompare(name(a));
        default:
          return incentives;
      }
    });
  },
  priceSort: function (incentives, direction) {
       return incentives?.sort((a, b) => {
         let price = (incentive) => {
          let typicalAmount = incentive["typical_amount"];
          if (typicalAmount === "N/A") {
            let leaseAmount = incentive.evaluation?.amount_in_lease;
            let purchaseAmount = incentive.evaluation?.amount_in_purchase;
            if(leaseAmount === 0 && purchaseAmount !== 0) {
                 return parseInt(purchaseAmount);
            } else if(purchaseAmount === 0 && leaseAmount !== 0) {
                 return parseInt(leaseAmount);
            } else {
                 return 0;
            }
          } else {
            return typicalAmount.split(" ").pop().replace(/[^0-9]+/g, "");
          }
        };

         switch (direction) {
           case "asc":
             return price(a) - price(b);
           case "desc":
             return price(b) - price(a);
           default:
             return incentives;
         }
       });
     },
};

export const providerSort = (incentives, provider, location) => {
       return incentives?.filter((incentive) => {
              if (!provider) {
                     return true;
              }

              return (
                     incentive.grantor === provider ||
                     ["Country", "Section", "State", "District", "Municipality", "Miscellaneous Organization"].includes(incentive.grantor_type)
              );
       }
    );
  };


export const sortIncentivesCatalog = (incentives, sortType, sortDirection) => {
  const clonedIncentives = cloneDeep(incentives);
  switch (sortType) {
    case "alphabetical":
      return Private.alphabeticalSort(clonedIncentives, sortDirection);
    default:
      return Private.priceSort(clonedIncentives, sortDirection);
  }
};
