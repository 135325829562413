const boolDefault = (val) => (typeof val === "boolean" ? val : null);

const loadUserPrefs = (savedPrefs) => {
  return {
    canTurnInClunker: boolDefault(savedPrefs.canTurnInClunker),
    chargerInstallAllowed: boolDefault(savedPrefs.chargerInstallAllowed),
    chargerLevel: savedPrefs.chargerLevel || null,
    electricMilesPortionForPhev: savedPrefs.electricMilesPortionForPhev || null,
    hasOvernightCharger: boolDefault(savedPrefs.hasOvernightCharger),
    gasolinePriceInCentsPerGal: savedPrefs.gasolinePriceInCentsPerGal || null,
    hasRegularPowerOutlet: boolDefault(savedPrefs.hasRegularPowerOutlet),
    householdIncome: savedPrefs.householdIncome || null,
    householdSize: savedPrefs.householdSize || null,
    electricityProvider: savedPrefs.electricityProvider || null,
    includeResaleValue: boolDefault(savedPrefs.includeResaleValue),
    interestRateAsBasisPoints: savedPrefs.interestRateAsBasisPoints || null,
    locallyAvailableVehicleFilter: boolDefault(
      savedPrefs.locallyAvailableVehicleFilter
    ),
    maxBudget: savedPrefs.maxBudget || null,
    milesDrivenAnnually: savedPrefs.milesDrivenAnnually || null,
    milesDrivenDaily: savedPrefs.milesDrivenDaily || null,
    minSeats: savedPrefs.minSeats || null,
    monthsOfOwnership: savedPrefs.monthsOfOwnership || null,
    parkingLocation: savedPrefs.parkingLocation || null,
    purchaseMethod: savedPrefs.purchaseMethod || null,
    showAllRates: boolDefault(savedPrefs.showAllRates),
    showGasolineCost: boolDefault(savedPrefs.showGasolineCost),
    showHomeElectricityCost: boolDefault(savedPrefs.showHomeElectricityCost),
    showRateDetails: boolDefault(savedPrefs.showRateDetails),
    showDetailsForRate: savedPrefs.showDetailsForRate || null,
    taxFilingStatus: savedPrefs.taxFilingStatus || null,
    vehicleChargingPattern: savedPrefs.vehicleChargingPattern || null,
    vehicleIdForIncentives: savedPrefs.vehicleIdForIncentives || null,
    vehicleHandleForIncentives: savedPrefs.vehicleHandleForIncentives || null,
    vehicleInsuranceYearly: savedPrefs.vehicleInsuranceYearly || null,
    vehicleRange: savedPrefs.vehicleRange || null,
    vehicleSortDirection: savedPrefs.vehicleSortDirection || null,
    vehicleSortType: savedPrefs.vehicleSortType || null,
    workingZipcode: savedPrefs.zipcode || null,
    zipcode: savedPrefs.zipcode || null,
    chargerBudget: savedPrefs.chargerBudget || null,
    chargerCordLength: savedPrefs.chargerCordLength || null,
    chargerSortType: savedPrefs.chargerSortType || null,
    chargerSortDirection: savedPrefs.chargerSortDirection || null,
    vehicleMakeFilter: savedPrefs.vehicleMakeFilter || null,
    vehicleModelFilter: savedPrefs.vehicleModelFilter || null,
    vehicleTrimFilter: savedPrefs.vehicleTrimFilter || null,
    incentiveSortType: savedPrefs.incentiveSortType || null,
    incentiveSortDirection: savedPrefs.incentiveSortDirection || null,
    vehicleAgeFilter: savedPrefs.vehicleAgeFilter || null,
    eventDistance: savedPrefs.eventDistance || null,
    eventLocation: savedPrefs.eventLocation || null,
    eventsSortType: savedPrefs.eventsSortType || null,
    salesTax: savedPrefs.salesTax || null,
    costPublicCharging: savedPrefs.costPublicCharging || null,
    publicChargingPercentage: savedPrefs.publicChargingPercentage || null,
    electricityRate: savedPrefs.electricityRate || null,
    municipality: savedPrefs.municipality || null,
    country: savedPrefs.country || null,
    userRegion: savedPrefs.userRegion || null,

    vehicleFuelTypeFilters: {
      bev: boolDefault(savedPrefs.vehicleFuelTypeFilters.bev),
      phev: boolDefault(savedPrefs.vehicleFuelTypeFilters.phev),
    },

    vehicleFormFactorFilters: {
      sedan: boolDefault(savedPrefs.vehicleFormFactorFilters.sedan),
      hatchback: boolDefault(savedPrefs.vehicleFormFactorFilters.hatchback),
      coupe: boolDefault(savedPrefs.vehicleFormFactorFilters.coupe),
      crossover: boolDefault(savedPrefs.vehicleFormFactorFilters.crossover),
      minivan: boolDefault(savedPrefs.vehicleFormFactorFilters.minivan),
      suv: boolDefault(savedPrefs.vehicleFormFactorFilters.suv),
      wagon: boolDefault(savedPrefs.vehicleFormFactorFilters.wagon),
      truck: boolDefault(savedPrefs.vehicleFormFactorFilters.truck),
    },
    chargerWifiFilter: {
      yes: boolDefault(savedPrefs.chargerWifiFilter.yes),
      no: boolDefault(savedPrefs.chargerWifiFilter.no),
    },

    chargerTypeFilters: {
      mounted: boolDefault(savedPrefs.chargerTypeFilters.mounted),
      portable: boolDefault(savedPrefs.chargerTypeFilters.portable),
    },

    chargerFormFactorFilters: {
      "6-20": boolDefault(savedPrefs.chargerFormFactorFilters["6-20"]),
      "6-30": boolDefault(savedPrefs.chargerFormFactorFilters["6-30"]),
      "10-30": boolDefault(savedPrefs.chargerFormFactorFilters["10-30"]),
      "14-30": boolDefault(savedPrefs.chargerFormFactorFilters["14-30"]),
      "6-50": boolDefault(savedPrefs.chargerFormFactorFilters["6-50"]),
      "14-50": boolDefault(savedPrefs.chargerFormFactorFilters["14-50"]),
      "14-60": boolDefault(savedPrefs.chargerFormFactorFilters["14-60"]),
      Hardwired: boolDefault(savedPrefs.chargerFormFactorFilters["Hardwired"]),
    },
  };
};

export default loadUserPrefs;
