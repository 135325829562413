import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import IncentiveCard from "../../../components/IncentiveCard/IncentiveCard";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import {
  sortIncentivesCatalog,
  providerSort,
} from "../../../functions/incentives/Sort/sortIncentivesCatalog";
// import { suitableIncentives } from "../../../functions/incentives/incentiveHelperFunctions"
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

const IncentiveCatalog = ({ incentives, titleText, location }) => {
  const userPrefs = useContext(UserPrefsContext);

  // removed temporarily
  // const validIncentives = suitableIncentives(incentives);
  const sortedIncentives = sortIncentivesCatalog(
    incentives,
    userPrefs.get("incentiveSortType"),
    userPrefs.get("incentiveSortDirection")
  );

  const filteredIncentives = providerSort(
    sortedIncentives,
    userPrefs.get("electricityProvider"),
    location
    );

  const renderCards = useMemo(() => {
    return filteredIncentives?.map((incentive) => (
      <IncentiveCard incentive={incentive} key={incentive.name} />
    ));
  }, [filteredIncentives]);

  if (!incentives) return <LoadingSpinner />;

  return (
    <div className="row mb-3">
      <div className="col-sm-12">
        {titleText && <p className="h3 text-left">{titleText}</p>}
        <div className="d-flex incentive-cards flex-wrap">
          {renderCards}
        </div>
      </div>
    </div>
  );
};

export default IncentiveCatalog;

IncentiveCatalog.propTypes = {
  incentives: PropTypes.array,
  titleText: PropTypes.element,
  includeTotal: PropTypes.bool,
};
