import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import ControlledInputZipcode from "../../../components/InputComponents/InputZipcode/ControlledInputZipcode";
import SlideHouseholdIncome from "../../../components/InputComponents/SlideHouseholdIncome/SlideHouseholdIncome";
import SelectHouseholdSize from "../InputComponents/SelectHouseholdSize/SelectHouseholdSize";
import ChooseTaxFilingStatus from "../InputComponents/ChooseTaxFilingStatus/ChooseTaxFilingStatus.js";
import ChooseCanTurnInClunker from "../InputComponents/ChooseCanTurnInClunker/ChooseCanTurnInClunker";
import IncentiveVehicleOptions from "../../components/IncentiveVehicleOptions/IncentiveVehicleOptions";
import ToolTip from "../../../components/shared/ToolTip/ToolTip";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { ValidateFiveDigitCode } from "../../../utils/Helpers/PostalCodeValidator";
import { FormattedMessage } from "react-intl";
import SelectElectricityProvider from "../InputComponents/SelectElectricityProvider/SelectElectricityProvider";

const IncentiveScoreOptions = ({ location, postcode, electricVehicles, isModal, onFormSubmitted }) => {
  const userPrefs = useContext(UserPrefsContext);
  const [workingZipcode, setWorkingZipcode] = useState(userPrefs.get("zipcode"));

  useEffect(() => {
    setWorkingZipcode(postcode);
  }, [postcode]);

  const handleFormSubmit = () => {
    if (ValidateFiveDigitCode(workingZipcode)) {
       userPrefs.toggleIncentivePrefsModal();
       if(workingZipcode !== postcode) {
              userPrefs.syncWorkingZipcode();
       }
       userPrefs.updateIncentives('click-event');
      if (isModal) {
        onFormSubmitted();
      }
    }
  };

  const RenderItem = ({ component, tooltip_id, title_id, message }) => (
    <div>
      <div className="incentive-label-select">
        {title_id && <FormattedMessage
          id={title_id}
          defaultMessage={"Item Title"}
          description={"Item Title"}
        />}
        <ToolTip message={message} id={tooltip_id} />
      </div>
      {component}
    </div>
  );
  const renderVehicleOptions = electricVehicles ? (
    <IncentiveVehicleOptions electricVehicles={electricVehicles} />
  ) : null;

  return (
    <div className="input-well MatchScoreOptions">
      <div
        style={{
          marginBottom: "20px",
          paddingBottom: "10px",
          borderBottom: "1px solid #ccc",
        }}
      >
        <ControlledInputZipcode
          zipcode={workingZipcode}
          handleSubmit={(e) => {}}
          workingZipcode={workingZipcode}
          setZipcode={(e) => {
            setWorkingZipcode(e.target.value);
            if (ValidateFiveDigitCode(e.target.value)) {
              userPrefs.set({ workingZipcode: e.target.value });
              userPrefs.updateElectricityProviders(e.target.value);
            }
          }}
          id="input-zipcode-for-rate-comp-launch"
          isUpdating={userPrefs.zipcodeIsUpdating}
          isNotFound={userPrefs.zipcodeIsNotFound}
          hideValidateRequest
        />
      </div>
      <div>{renderVehicleOptions}</div>
      <p className="h2">
        <FormattedMessage
          id="incentives.eligibility"
          defaultMessage=" Eligibility Filters"
          description=" Eligibility Filters"
        />
      </p>
      <RenderItem
        component={<SelectElectricityProvider location={location} />}
        tooltip_id="electricity_provider_tooltip"
        title_id={"incentives.electricityProvider"}
        message="Determines eligibility for incentives offered by electricity provider(s) servicing your zip code."
      />
      <RenderItem
        component={<SlideHouseholdIncome />}
        tooltip_id="household_income_tooltip"
        title_id={"incentives.householdIncome"}
        message="Combine income from all household members. Used to determine incentive eligibility."
      />
      <RenderItem
        component={<SelectHouseholdSize />}
        tooltip_id="household_size_tooltip"
        title_id={"incentives.householdSize"}
        message="Include all members of your household (spouse, dependents, etc). Used to determine incentive eligibility."
      />
      <RenderItem
        component={<ChooseTaxFilingStatus />}
        tooltip_id="filing_status_tooltip"
        title_id={"incentives.taxFilingStatus"}
        message="Used to determine incentive eligibility."
      />
      <RenderItem
        component={<ChooseCanTurnInClunker />}
        tooltip_id="trade_in_tooltip"
        title_id={"incentives.tradeIn"}
        message="Trading in an old gas vehicle may be required for certain incentives."
      />
      <button onClick={() => handleFormSubmit()} className="acura-button">
        <FormattedMessage
          id="incentives.applyButton"
          defaultMessage="APPLY FILTERS"
          description="APPLY FILTERS"
        />
      </button>
      <div className="incentives-disclaimer">
        <FormattedMessage
          id="incentives.disclaimer"
          defaultMessage="Please note that exact eligibility may vary based on numerous factors. For the most accurate information, we invite you to contact the incentive grantor and/or your tax professional. Additionally, the information on this site is continuously being updated with the latest ZDX eligibility for federal, state and local incentives. We invite you to check back again at a later date."
          description="Please note that exact eligibility may vary based on numerous factors. For the most accurate information, we invite you to contact the incentive grantor and/or your tax professional. Additionally, the information on this site is continuously being updated with the latest ZDX eligibility for federal, state and local incentives. We invite you to check back again at a later date."
        />
      </div>
    </div>
  );
};

export default IncentiveScoreOptions;

IncentiveScoreOptions.propTypes = {
  electricVehicles: PropTypes.array,
};
