import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";

import Select from "../../../../components/shared/InputElements/Select";

const SelectHouseholdSize = ({ id = "household-size", ...rest }) => {
  const userPrefs = useContext(UserPrefsContext);

  return (
    <Select
      id={id}
      isSpecial={true}
      value={userPrefs.get("householdSize").toString()}
      optionNames={["1", "2", "3", "4", "5", "6", "7", "8"]}
      optionValues={[1, 2, 3, 4, 5, 6, 7, 8]}
      handler={(e) => userPrefs.set({ householdSize: e.target.value })}
    />
  );
};

export default SelectHouseholdSize;

SelectHouseholdSize.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
};
