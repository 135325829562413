import React, { useContext }  from "react";
import PropTypes from "prop-types";
import logo from "../../../assets/images/Acura_wide_logo.svg";
import mobileLogo from "../../../assets/images/Acura_Logo_Stacked_White_RGB.svg";
import "./Header.scss";
import GaTracker from "../../../../utils/GaTracker/GaTracker";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";

const Header = ({ page }) => {
  const userPrefs = useContext(UserPrefsContext);
  const isMobile = window.innerWidth < 426;

  const handleClick = (e) => {
       e.preventDefault();
       e.stopPropagation();

       const url = "https://www.acura.com";

       const userPreferences = {
              zipcode: userPrefs.get("zipcode"),
              userRegion: userPrefs.get('userRegion'),
       };

       GaTracker.trackEvent({
              event: "zr-click-event",
              category: "Acura Logo",
              eventName: "link",
              label: "Acura Electric Vehicle and Charger Incentives:Incentive Page:Acura Logo",
              href: "https://www.acura.com",
              userPrefs: userPreferences,
       });

       window.open(url, '_blank');
 };


  return (
      <header className="Header" role="banner">
        <div
          className="container"
          style={{ padding: 0, maxWidth: "1224px", marginLeft: "0px" }}
        >
          <div
            className="logo-container"
            style={{
              width: "180px",
            }}
          >
            <a
              href="https://www.acura.com"
              className={
                isMobile ? `navbar-brand` : `navbar-brand desktop`
              }
              onClick={(e) => handleClick(e)}
            >
              <img
                className={
                  isMobile ? `img-mobile` : ``
                }
                src={isMobile ? mobileLogo : logo}
                alt="Acura"
              />
            </a>
          </div>
        </div>
    </header>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string,
};
