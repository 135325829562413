// import GoogleAnalytics from "react-ga4";
import { trackAdobePageView, trackAdobeEvent } from "../AdobeTracker/AdobeTracker";
// import GoogleAnalytics from "react-ga4";
// const trackers = [];

// Avoiding having trackers populate as a side effect of importing the module.
// const hydrateTrackers = () => {
//        if (trackers.length) {
//               return;
//        }

//        switch (process.env.REACT_APP_ENVIRONMENT) {
//               case "production":
//                      if(process.env.REACT_APP_GA_CLIENT_PRODUCTION_TRACKING_ID) {
//                             trackers.push({
//                                    trackingId: process.env.REACT_APP_GA_CLIENT_PRODUCTION_TRACKING_ID,
//                                    gaOptions: {
//                                           name: process.env.REACT_APP_GA_CLIENT_PRODUCTION_TRACKING_OPTIONS_NAME,
//                                    },
//                             });
//                      }
//                      if(process.env.REACT_APP_GA_ZAPPYRIDE_PRODUCTION_TRACKING_ID) {
//                             trackers.push({
//                                    trackingId: process.env.REACT_APP_GA_ZAPPYRIDE_PRODUCTION_TRACKING_ID,
//                                    gaOptions: {
//                                           name: process.env.REACT_APP_GA_ZAPPYRIDE_PRODUCTION_TRACKING_OPTIONS_NAME,
//                                    },
//                             });
//                      }
//               break;

//               default:
//                      if(process.env.REACT_APP_GA_ZAPPYRIDE_STAGING_TRACKING_ID) {
//                             trackers.push({
//                                    trackingId: process.env.REACT_APP_GA_ZAPPYRIDE_STAGING_TRACKING_ID,
//                                    gaOptions: {
//                                           name: process.env.REACT_APP_GA_CLIENT_TRACKING_OPTIONS_NAME,
//                                    },
//                             });
//                      }
//                      if(process.env.REACT_APP_GA_CLIENT_STAGING_TRACKING_ID) {
//                             trackers.push({
//                                    trackingId: process.env.REACT_APP_GA_CLIENT_STAGING_TRACKING_ID,
//                                    gaOptions: {
//                                           name: process.env.REACT_APP_GA_CLIENT_STAGING_TRACKING_OPTIONS_NAME,
//                                    },
//                             });
//                      }
//        }
// };

// hydrateTrackers();

const GaTracker = {
  initialize: () => {
    //if (trackers.length) {
    //  GoogleAnalytics.initialize(trackers);
    //}
  },

  getPageName: (page) => {
    const lastCharIndex = page.length - 1;

    return page.length > 1 && page.charAt(lastCharIndex) === "/"
      ? page.substring(0, lastCharIndex)
      : page;
  },

  // For testing purposes only.
  doHydrate: () => {
    //hydrateTrackers();
    //GaTracker.initialize();
  },
  trackPage: (page, userPrefs) => {
    page = GaTracker.getPageName(page);
       trackAdobePageView(page, userPrefs);
  },
  trackEvent: ({
       event,
       category,
       eventName,
       label,
       href,
       incentive,
       userPrefs
  }) => {
       trackAdobeEvent(event, category, eventName, label, href, incentive, userPrefs);
  }
};

export default GaTracker;
