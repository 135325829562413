import { loadState } from "../LocalStorage/LocalStorage";

const formatDate = (date) => {
       const month = (date.getMonth() + 1).toString().padStart(2, '0');
       const day = date.getDate().toString().padStart(2, '0');
       const year = date.getFullYear();
       return `${month}/${day}/${year}`;
};


const formatVersionDate = (date) => {
       var year = date.getFullYear();
       var month = date.getMonth() + 1;
       var formattedDate = year + "." + (month < 10 ? "0" : "") + month;
       return formattedDate;
};

const getReferrerType = (referrer) => {
       if (referrer === null || referrer === "") {
              return "typed/bookmark";
          } else if (referrer.includes("google") || referrer.includes("bing") || referrer.includes("yahoo") || referrer.includes("duckduckgo")) {
              return "search_engine";
          } else if (referrer.includes("facebook") || referrer.includes("twitter") || referrer.includes("instagram") || referrer.includes("pinterest")) {
              return "social_media";
          } else if (referrer.includes("acura")) {
              return "internal";
          } else {
              return "external";
       }
};

const getAcuraReferrer = (referrerUrl) => {
       if (referrerUrl.includes("acura")) {
              return true;
       } else {
              return false;
       }
};

const createBaseDataObject = (pageName) => {
       let referrerUrl = document.referrer.toLowerCase();
       let referrerType = getReferrerType(referrerUrl);
       let internalReferrer = getAcuraReferrer(referrerUrl) ? referrerUrl : null;

       return {
              metadata: {
                     property_name: "electric_acura",
                     version_dl: formatVersionDate(new Date()),
              },
              page: {
                     brand_name: "Acura",
                     page_name: "Acura Electric Vehicle and Charger Incentives",
                     content_publish_date: formatDate(new Date()),
                     environment: process.env.REACT_APP_ENVIRONMENT,
                     internal_referrer: internalReferrer,
                     full_url: window.location.href,
                     page_friendly_url: window.location.origin + window.location.pathname,
                     site_section: "incentives",
                     referrer_url: referrerUrl,
                     referrer_type: referrerType,
              },
       };
};

export const trackAdobePageView = (page, userPrefs) => {
    const dataObject = {
      ...createBaseDataObject(page),
      event: "zr-PageLoadReady",
      ...processCustomData(userPrefs),
    };

    window.digitalData = window.digitalData || [];
    window.digitalData = dataObject;

    if (window._satellite) {
      window._satellite.track("zr-PageLoadReady", dataObject);
    }
  };

  export const trackAdobeEvent = (event, category, eventName, label, href, incentive, userPrefs) => {
    let eventData = createBaseDataObject("acura");

       eventData = {
              ...eventData,
              event: event,
              event_metadata: {
                     action_category: category,
                     action_label: label,
                     action_type: eventName,
              },
              ...processCustomData(userPrefs),
       };

       if(href) {
              eventData.metadata.target_url = href;
      }

      if(incentive) {
              eventData.offer = {
                     incentive: incentive.type + " : " + incentive.grantor + " : " + incentive.name,
              };
      }

       if (window._satellite) {
              setTimeout(() => {
                     window._satellite.track(event, eventData);
              }, 1000);
       }

       window.digitalData = window.digitalData || [];
       window.digitalData = eventData;
  };

const processCustomData = (userPrefs) => {
       const existingState = loadState() || {};
       const cookie_consent_category = window.OnetrustActiveGroups !== undefined ? window.OnetrustActiveGroups : null;

       let taxStatus = existingState.userPreferences?.taxFilingStatus;
       if(taxStatus === "joint") {
              taxStatus = "married";
       }

       return {
              model: {
                     model_name: 'zdx',
                     model_year: '2024',
                     model_trim: existingState.userPreferences?.vehicleTrimFilter ?? 'A-Spec AWD',
              },
              user: {
                     electricity_provider: existingState.userPreferences?.electricityProvider,
                     household_income: existingState.userPreferences?.householdIncome ?? 70000,
                     size_of_household: existingState.userPreferences?.householdSize ?? 3,
                     tax_filing_status: taxStatus ?? 'married',
                     has_trade_in: existingState.userPreferences?.canTurnInClunker === 'true' ? 'Yes' : 'No',
                     user_zipcode: userPrefs?.zipcode ?? existingState.userPreferences?.zipcode ?? '90504',
                     user_status: "not-logged-in",
                     user_region: userPrefs?.userRegion ?? existingState.userPreferences?.userRegion?.region ?? "",
                     cookie_consent_category: cookie_consent_category,
              },
       };
};
