import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import IncentiveCatalog from "../../client_customizations/components/IncentiveCatalog/IncentiveCatalog";
import IncentiveSortControls from "../../client_customizations/components/IncentiveSortControls/IncentiveSortControls";
import LoadingSpinner from "./../../components/LoadingSpinner/LoadingSpinner";
import { FormattedMessage } from "react-intl";
import ModalComponent from "../../components/ModalUIComponent/ModalUIComponent";
import IncentiveScoreOptions from "../../client_customizations/components/IncentiveScoreOptions/IncentiveScoreOptions";
import { useIntl } from "react-intl";
import GaTracker from "../../utils/GaTracker/GaTracker";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import "./Incentives.scss";

const Incentives = ({ electricVehicles, incentives, loading, location, postcode, ip, uuid }) => {
       const userPrefs = useContext(UserPrefsContext);
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false)
  const handleModalUpdate = (value) => setShowModal(value ? value : !showModal)
  useEffect(() => {
    document.title = "Electric Vehicle and Charger Incentives | Acura";
  });
  const renderIncentives = loading ? (
    <LoadingSpinner />
  ) : (
    <IncentiveCatalog incentives={incentives} location={location} category="all" />
  );

  const handleClickBuildButton = (e) => {
       e.preventDefault(); 
       const url = "https://www.acura.com/tools/powertrain-selector?modelYear=2024&modelSeries=zdx";
       const userPreferences = {
              zipcode: userPrefs.get("zipcode"),
              userRegion: userPrefs.get('userRegion'),
       };

       GaTracker.trackEvent({
              event: "zr-click-event",
              category: "Acura Build Button",
              eventName: "button",
              label: "Acura Electric Vehicle and Charger Incentives:Incentive Page:Start Building Your ZDX",
              href: "https://www.acura.com/tools/powertrain-selector?modelYear=2024&modelSeries=zdx",
              userPrefs: userPreferences,
       });
       window.open(url, '_blank');
 };

  const renderOptions = (isModal) => (
    <>
      {isModal ? <IncentiveSortControls /> : null}
      <IncentiveScoreOptions
        electricVehicles={electricVehicles}
        location={location}
        postcode={postcode}
        onFormSubmitted={handleModalUpdate}
        isModal={isModal}
      />
      <br />
    </>
  );
  return (
    <section className="ev-container-fluid" id="Incentives">
      <div className="row mb-3 incentives-header">
        <div className="col-sm-12">
          <h1 className="evHead">
            <FormattedMessage
              id="incentives.title.alt"
              defaultMessage="Electric Vehicle and Charger Incentives "
              description="Electric Vehicle and Charger Incentives "
            />
          </h1>
          <p className="lead" style={{ paddingTop: 0 }}>
            <FormattedMessage
              id="evs.incentives.subTitle"
              defaultMessage="Whether you're buying or leasing, your Acura ZDX may be eligible for a range of incentives, including rebates, tax credits, and other benefits. Adjust the eligibility filters to personalize the results.*"
              description="Whether you're buying or leasing, your Acura ZDX may be eligible for a range of incentives, including rebates, tax credits, and other benefits. Adjust the eligibility filters to personalize the results.*"
              values={{
                lineBreak: <br />,
              }}
            />
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="pull-right d-none d-lg-block">
            <IncentiveSortControls />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 d-block d-lg-none text-center">
          <ModalComponent
            isOpen={showModal}
            onStatusChanged={handleModalUpdate}
            buttonText={
              intl.formatMessage
                ? intl.formatMessage({
                    id: "incentives.adjustFilters",
                    defaultMessage: "ADJUST FILTERS",
                  })
                : "ADJUST FILTERS"
            }
            titleText={
              intl.formatMessage
                ? intl.formatMessage({
                    id: "incentives.filters",
                    defaultMessage: "Filters",
                  })
                : "Filters"
            }
          >
            {renderOptions(true)}
          </ModalComponent>
          <br />
        </div>
        <div className="col-xl-3 col-lg-4 d-none d-lg-block">
          {renderOptions(false)}
        </div>
        <div
          className="contentCol col-xl-9 col-lg-8"
          style={{ paddingLeft: 10 }}
        >
          {renderIncentives}

          <div style={{ padding: "25px 0 50px" }}>
              <p style={{fontSize:"14px"}}><FormattedMessage
                     id="incentives.buildButton.disclaimer"
                     defaultMessage="* Acura ZDX incentive eligibility subject to approval by federal, state and local governments; final approval currently may be pending. We invite you to check back again at a later date. Certain state/local incentives have limited funding which may be depleted at time of your ZDX purchase."
                     description="* Acura ZDX incentive eligibility subject to approval by federal, state and local governments; final approval currently may be pending. We invite you to check back again at a later date. Certain state/local incentives have limited funding which may be depleted at time of your ZDX purchase."
              /></p>
              <a
              href="https://www.acura.com/tools/powertrain-selector?modelYear=2024&modelSeries=zdx"
              className= "acura-flex-button"
              onClick={handleClickBuildButton}
              >
              <button
              className="acura-button small flex-button"
              style={{ marginTop: "20px "}}
              >
              <FormattedMessage
                     id="incentives.buildButton"
                     defaultMessage="START BUILDING YOUR ZDX"
                     description="START BUILDING YOUR ZDX"
              />
              </button>
              </a>
       </div>
        </div>
      </div>
    </section>
  );
};

export default Incentives;

Incentives.propTypes = {
  incentives: PropTypes.array,
  electricVehicles: PropTypes.array,
  zip: PropTypes.string,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
