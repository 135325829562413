import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";

import Select from "../../../../components/shared/InputElements/Select";
import { useIntl } from "react-intl";
import unique from "lodash/uniq";

const SelectVehicleModelFilter = ({
  id = "select-vehicle-model-filter",
  vehicles,
  makeOption,
  ...rest
}) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage
    ? intl.formatMessage({ id: "model", defaultMessage: "Model" })
    : "Model";

  const vehicleModelOptions = (vehicles, makeOption) => {
    if (makeOption) {
      vehicles = vehicles.filter((vehicle) => {
        return vehicle.make === makeOption;
      });

      vehicles.sort((a, b) => {
        return a.model.localeCompare(b.model);
      });

      return unique((vehicles || []).map((ev) => ev.model_year + " " + ev.make + " " + ev.model));
    }
  };

  return (
    <Select
      id={id}
      value={userPrefs.get("vehicleModelFilter")}
      label={label}
      optionNames={[
        ...(vehicleModelOptions(vehicles, makeOption) || []),
      ]}
      optionValues={[...(vehicleModelOptions(vehicles, makeOption) || [])]}
      isSpecial={true}
      isSingleValue={true}
      handler={(e) =>
        userPrefs.set({
          vehicleModelFilter: e.target.value,
        })
      }
    />
  );
};

export default SelectVehicleModelFilter;

SelectVehicleModelFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
