import React, { useContext } from "react";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { FormattedMessage, useIntl } from "react-intl";

const IncentiveSortControls = (isModal) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  const updateSort = (value) => {
    let newPrefs = {
      incentiveSortType: value.split("-")[0],
      incentiveSortDirection: value.split("-")[1],
    };
    userPrefs.set(newPrefs);
  };

  let sortOptionValue = [
    userPrefs.get("incentiveSortType"),
    userPrefs.get("incentiveSortDirection"),
  ].join("-");

  return (
    <div className="EVsSort">
      <form className="form-inline">
        <label
          htmlFor="vehicle-sort-dropdown"
          className="mr-2"
          style={{ color: "#1E2123" }}
        >
          <FormattedMessage
            id="evSort.title"
            defaultMessage="Sort By"
            description="EV Sort By Title"
          />
        </label>
        <select
          id="vehicle-sort-dropdown"
          className="form-control-special"
          value={sortOptionValue}
          onChange={(e) => updateSort(e.target.value)}
        >
          <option value="price-desc">
            {intl.formatMessage
              ? intl.formatMessage({
                  id: "incentiveSort.largest",
                  defaultMessage: "Value: High to Low",
                })
              : "Value: High to Low"}
          </option>
          <option value="price-asc">
            {intl.formatMessage
              ? intl.formatMessage({
                  id: "incentiveSort.smallest",
                  defaultMessage: "Value: Low to High",
                })
              : "Value: Low to High"}
          </option>
          <option value="alphabetical-asc">
            {intl.formatMessage
              ? intl.formatMessage({
                  id: "incentiveSort.aToZ",
                  defaultMessage: "Alphabetical: A to Z",
                })
              : "Alphabetica;: A to Z"}
          </option>
          <option value="alphabetical-desc">
            {intl.formatMessage
              ? intl.formatMessage({
                  id: "incentiveSort.zToA",
                  defaultMessage: "Alphabetical: Z to A",
                })
              : "Alphabetica;: Z to A"}
          </option>
        </select>
      </form>
    </div>
  );
};

export default IncentiveSortControls;
